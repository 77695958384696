import React from 'react';

import { cookies, do_get, get_url_param} from '../utils.jsx';


export class IssoForm extends React.Component {
	constructor(props) {
		super(props);
	}
	
    componentDidMount() {
        var id = get_url_param("id");
        var token = get_url_param("token");
        var default_next_url = '/my-list/';

        do_get(window.wpco_api_prefix + 'Account/isso', {
            id: id,
            token: token
        }, (ret)=>{
            if(ret.responseType!=1) {
                toastr.error(ret.message || "Unknown error.");
                return;
            }

            cookies.set('wpaT', ret.authToken, {path:'/', maxAge:14*86400, secure:true});
            cookies.set('wpgLS', JSON.stringify(ret.giftListSLA), {path:'/', maxAge:14*86400, secure:true});
            window.location.href = get_url_param('next') || default_next_url;
        });
    }

	render() {
		return <div>
            <div className="loading-spinner"></div><br /><br /><br />
		</div>;
	}
	
}
