import React from 'react';

export class ItemSwiper extends React.Component {
    constructor(props) {
        super(props);
        this.swiperRef = React.createRef();
        this.initialized = false;
    }

    render() {
        const children = this.props.children;

        return <div className="twelve columns item-swiper product-carousel" ref={this.swiperRef}>
            { children }
        </div>;
    }

    componentDidMount() {
        //this.componentDidUpdate();
    }

    componentDidUpdate() {
        if(this.props.children.length) {
            if(this.initialized) {
                window.$(this.swiperRef.current).slick('unslick');
            }
            //
            window.$(this.swiperRef.current).slick({
                slidesToShow: 4,
                slidesToScroll: 4,
                // dots:true,
                arrows:true,

                responsive: [{
                    breakpoint: 719,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                }, {
                    breakpoint: 1050,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                }, {
                    breakpoint: 850,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                }, {
                    breakpoint: 450,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }],
            });
            this.initialized = true;
        }
    }
}